import React, { useState,useEffect } from 'react';
import axios from 'axios';
//import 'bootstrap/dist/css/bootstrap.css';
import * as ReactDOM from "react-dom/client";
import {Snackbar,Alert,Box,Grid,Paper, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, CircularProgress ,Stack, Tooltip, Container} from '@mui/material';
import {createBrowserRouter, Route, RouterProvider, useNavigate } from "react-router-dom";
import Lottie from 'lottie-react';
import Nav from './composants/nav';
import Inscri from './images/lottie/inscriL.json';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FooterCoIns from './composants/footerCoIns';


var BACKEND_URL = 'https://backend.bet-partners.com/api/';



export default function Connexion() {

    const [load,setLoad]= useState();
    // État pour les données du formulaire de connexion
  const [formData, setFormData] = useState({ email: '', password: '' });
  
  // État pour gérer l'affichage du Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error'); // Options: 'success', 'error', 'warning', 'info'

  // Gestion de l'état pour les champs du formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Envoi des données au backend pour la connexion
      const response = await axios.post(`${BACKEND_URL}login`, {
        email: formData.email,
        password: formData.password,
      });

      // Si connexion réussie
      setSnackbarMessage(`Connexion réussie ! Bienvenue ${response.data.name || 'Utilisateur'}`);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

      // Stocker l'utilisateur connecté (par exemple, dans le localStorage ou dans un état global)
      localStorage.setItem('user', JSON.stringify(response.data));

      console.log(response.data)
      // Rediriger ou effectuer une action après connexion réussie
      window.location.href = `/dashboard/${response.data.id}/${response.data.type_user}`;
      
    } catch (error) {
      // Si une erreur survient, afficher un message d'erreur
      if (error.response && error.response.data.error) {
        setSnackbarMessage(`Erreur: ${error.response.data.error}`);
      } else {
        setSnackbarMessage('Une erreur est survenue lors de la tentative de connexion.');
      }
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Fonction pour fermer le Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
   
    return (        
      <div>
        <Nav />
        <div class="container-fluid">
            <div class="row">
           
                <div class="col-sm-6" style={{ display: 'flex',
                 justifyContent: 'center',
                  alignItems: 'center',
                   height: '100vh' }}>
                    {/* Colonne de gauche (Formulaire) */}
                    
        <Container maxWidth='xs'>
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 4 }}>
        <Box textAlign="center" mb={2}>
          <Typography sx={{fontSize:windowWidth>500?25:20,      
       textAlign: 'left',
      fontFamily:'monospace',
      maxWidth:180,
         mb: 2 }} gutterBottom>
                  Connexion
          </Typography>
        </Box>
        <form>
          {/* Champ E-mail */}
          <Box mb={2}>
            <TextField
              fullWidth
              label="E-mail"
              type="email"
              name='email'
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              required
              sx={{ marginBottom: 2 }}
            />
          </Box>

          {/* Champ Mot de passe */}
          <Box mb={2}>
            <TextField
              fullWidth
              label="Mot de passe"
              type="password"
              name='password'
              variant="outlined"
              value={formData.password}
              onChange={handleChange}
              required
              sx={{ marginBottom: 2 }}
            />
            <Box textAlign="right">
             <Button href="/forgotPassword"
              variant="text"
              style={{
                fontFamily:'monospace',
                fontSize:12,
                textTransform: 'none'
              }} 
              color="primary">Mot de passe oublié ?</Button>

          </Box>
          </Box>

          {/* Bouton de Connexion */}
          <Box textAlign="center">
            <Button variant="contained" fullWidth style={{backgroundColor:'#435666',
            padding: 5,
               fontSize:20,
               borderRadius:7,
               fontWeight:'bold',
            fontFamily:'monospace',
            textTransform: 'none',        
               color:'white'}}
               onClick={handleSubmit}>
              Connexion
            </Button>
          </Box>

          {/* Lien d'inscription */}
          <Box mt={3} textAlign="center">
            <Typography variant="body1">
              Pas encore inscrit ? <Button href="/inscription" variant="text" color="primary">Créer un compte</Button>
            </Typography>
          </Box>          
        </form>
      </Paper>
       {/* Affichage du Snackbar pour les messages */}
       <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
      </div>
      {windowWidth>500?
                <div class="col-sm-6" style={{backgroundColor:'white',
               height: '100vh',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center'}}>
                    
                    <Lottie animationData={Inscri} loop style={{
                            height:windowWidth>500? 450:350,
                            width:windowWidth>500? 450:350
                        }}/>                                        
                </div>
                :
                <div></div>
                      }
            </div>
    </div>
    <FooterCoIns />
    </div>
    
);
}
/*else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}
}*/
