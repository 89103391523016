import React, { useState, useEffect } from 'react';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import aproposF from '../../images/lottie/aproposF.json'




export default function FirstSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5">
            <div className="row align-items-center">
                {/* Partie gauche : contenu */}
                <div className="col-md-6">
                    <div className="card border-0" style={{ padding: '10px' }}>
                    <p className="card-text" style={{fontWeight:'',color:'#435666', fontSize:windowWidth>500? 20:15}}>
                            Nos meilleurs bookmaker?
                        </p>
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <div style={{width:5,
                             height:100,
                             borderRadius:5,
                             backgroundColor:'#435666'}}></div>
                        <p className="card-title" style={{fontWeight:'bold', width:200, fontSize:windowWidth>500? 25:20}} >
                            BOOKMAKER BET-PARTNERS
                        </p>
                        </Stack>
                        <p className="card-text" style={{fontWeight:'', fontSize: windowWidth>500?  20:15}}>                        
                        Trouver un bon bookmaker peut être difficile,
                         car de nombreuses offres ne sont pas fiables.
                          Voici deux solutions pour identifier
                           les meilleurs et éviter les faux.
                          </p>
                          <Stack direction={'row'} alignItems={'center'} spacing={2}>
                          <Lottie animationData={start} loop style={{
                            height:100,
                            width:100
                        }}/>
                          <Button variant="contained" 
                          href='/inscription'
                          style={{width:150, height:40,
                            backgroundColor:'#435666',}}>
                            Commencer
                          </Button>

                          </Stack>
                    </div>
                </div>
                
                {/* Partie droite : image */}
                <div className="col-md-6">
                <Lottie animationData={aproposF} loop style={{
                            height:windowWidth>500? 600:350,
                            width:windowWidth>500? 600:350
                        }}/>
                </div>
            </div>
        </div>
    );
}
