import React from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Button, Typography, CardActions } from '@mui/material';
import melbet from '../../images/melbet.png'
import xbet from '../../images/1xbet.png'
import betwin from '../../images/betwin.png'
import win from '../../images/1win.jpg'
import linebet from '../../images/Linebet.jpg'
import bet from '../../images/bet.png'
import betLyrics from '../../images/bet-Lyrics.png'

// Exemple d'images de partenaires (remplacez par les vôtres)
const partnerLogos = [
  { name: '1xbet', imgSrc: xbet, code: 'MHPRONO', lien: 'https://affpa.top/L?tag=d_3780009m_97c_&site=3780009&ad=97' },
  { name: 'BetWinner', imgSrc: betwin, code: 'B2030', lien: 'https://bwredir.com/2gGO?extid=https://www.bet-partners.com/&p=%2Fregistration%2F' },
  { name: '1win', imgSrc: win, code: 'B2030', lien:'https://1wnxy.com/?open=register&p=wy8h' },
  { name: 'MelBet', imgSrc: melbet, code: 'B2030', lien:'https://refpakrtsb.top/L?tag=d_3780068m_66335c_&site=3780068&ad=66335' },
  { name: 'LineBet', imgSrc: linebet, code: 'B2030', lien : 'https://lb-aff.com/L?tag=d_3780071m_22611c_&site=3780071&ad=22611' },
];

const PartenaireBookSection = () => {
  return (
    <Box sx={{ padding: '50px 10%', backgroundColor: '#f8f9fa' }}>
        <Typography variant="subtitle1" align="center" sx={{ mb: 1, color:'#435666' }}>
        <img
              src={bet}
              //onClick={() => {navigate(`/`)}}
              width={50}
              height={50} />          
        </Typography>

      {/* Titre de la section */}
      <Typography variant="h5" gutterBottom align="center" sx={{mb:5, fontWeight: 'bold' }}>
       Nos bookmakers !!!
      </Typography>
      
      {/* Grille des cartes de partenaires */}
      <Grid container spacing={4} justifyContent="center">
        {partnerLogos.map((partner, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card
            onClick={(e) => {
              window.open(`${partner.lien}`, '_blank');              
            }} 
            sx={{ 
              ':hover': { 
                boxShadow: 6, // Augmente l'ombre au survol pour un effet dynamique 
                transform: 'scale(1.05)' // Zoom léger au survol
              },
              backgroundColor:'#6589a8',
              transition: 'transform 0.3s ease-in-out' 
            }}>
              <CardMedia
                component="img"
                height="140"
                image={partner.imgSrc} // Image du partenaire
                alt={partner.name}
                sx={{ padding: 2, objectFit: 'cover' }}
              />
              <CardContent>
                <Typography gutterBottom style={{fontSize:'15px', 
                  color:'white', fontWeight:'bold'}} component="div" align="center">
                Des offres de bienvenue abondantes               
                </Typography>                
              </CardContent>
              <CardActions >                
              <Button align="center" fullWidth style={{fontWeight:'bold',
              backgroundColor:'#435666',
                fontFamily:'', color:'white'}}>
                   Code Promo : {partner.code}
                </Button>
              </CardActions>   
              <CardActions >                
              <Button align="center" fullWidth style={{fontWeight:'bold',
              backgroundColor:'#ff2626',
                fontFamily:'', color:'white'}}>
                   Demander un bonus
                </Button>
              </CardActions>              
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PartenaireBookSection;
