import React, { useState, useEffect } from 'react';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import africa from '../../images/lottie/africa.json'




export default function FirstSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);

      
    
    return (
        <div className="container my-5">
            <div className="row align-items-center">
                {/* Partie gauche : contenu */}
                <div className="col-md-6">
                    <div className="card border-0" style={{ padding: '10px' }}>
                    <p className="card-text" style={{fontWeight:'', fontSize:windowWidth>500? 25:15}}>
                            
                        </p>
                        <p className="card-title" style={{fontWeight:'bold', fontSize:windowWidth>500? 45:30}} >
                            Gagner de l'argent avec nous.
                            </p>
                        <p className="card-text" style={{fontWeight:'', fontSize: windowWidth>500?  25:15}}>
                        Comment ça marche ? En quelques mots<br />
                        </p>
                        <p className="card-text" style={{fontWeight:'', fontSize: windowWidth>500?  20:15}}>
                        -Inscrivez-vous sur BET-Partners.<br />
                        -Attirez de nouveaux joueurs (1xbet,BetWinner,1win,MelBet,LineBet).<br />
                        -Recevez une commission pour chaque joueur parrainé.<br />
                          </p>
                          <Stack direction={'row'} alignItems={'center'} spacing={2}>
                          <Lottie animationData={start} loop style={{
                            height:100,
                            width:100
                        }}/>
                          <Button variant="contained" 
                          href='/inscription'
                           style={{width:150, height:40,
                            backgroundColor:'#435666',}}>
                            S'inscrire
                          </Button>

                          <Button variant="outlined" 
                          href='/ccm'
                           style={{width:150,
                             height:40, 
                             fontSize:windowWidth>470?12:10                          
                            }}>
                            En savoir plus
                          </Button>

                          </Stack>
                          
                    </div>
                </div>
                
                {/* Partie droite : image */}
                <div className="col-md-6">
                <Lottie animationData={africa} loop style={{
                            height:windowWidth>500? 600:350,
                            width:windowWidth>500? 600:350
                        }}/>
                </div>
            </div>
        </div>
    );
}
