// BookmakersTable.js
import React from 'react';
import { Table, TableBody,Typography, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import melbet from '../../images/melbet.png'
import xbet from '../../images/1xbet.png'
import betwin from '../../images/betwin.png'
import win from '../../images/1win.jpg'
import linebet from '../../images/Linebet.jpg'
import bet from '../../images/bet.png'
import betLyrics from '../../images/bet-Lyrics.png'

import { useState, useEffect } from 'react'

// Exemple d'images de partenaires (remplacez par les vôtres)
const bookmakers = [
  { name: '1xbet', imgSrc: xbet, code: 'MHPRONO', lien: 'https://affpa.top/L?tag=d_3780009m_97c_&site=3780009&ad=97' },
  { name: 'BetWinner', imgSrc: betwin, code: 'B2030', lien: 'https://bwredir.com/2gGO?extid=https://www.bet-partners.com/&p=%2Fregistration%2F' },
  { name: '1win', imgSrc: win, code: 'B2030', lien:'https://1wnxy.com/?open=register&p=wy8h' },
  { name: 'MelBet', imgSrc: melbet, code: 'B2030', lien:'https://refpakrtsb.top/L?tag=d_3780068m_66335c_&site=3780068&ad=66335' },
  { name: 'LineBet', imgSrc: linebet, code: 'B2030', lien : 'https://lb-aff.com/L?tag=d_3780071m_22611c_&site=3780071&ad=22611' },
];

const BookmakersTable = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    

  return (
    <div className="container my-5" style={{
        //backgroundColor:'#435666',
        borderRadius:'30px',
        padding:20,
        margin: ''
    }}>
        <Typography variant="subtitle1" align="center" sx={{ mb: 1, color:'#435666' }}>
        <img
              src={bet}
              //onClick={() => {navigate(`/`)}}
              width={50}
              height={50} />          
        </Typography>

      {/* Titre de la section */}
      <Typography variant="h5" gutterBottom align="center" sx={{mb:0, fontWeight: 'bold' }}>
      Meilleurs bookmakers
      </Typography>
      {windowWidth>700?
    <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Table>
        <TableHead>
          <TableRow style={{
            backgroundColor:'#435666'
          }}>            
            <TableCell align="left" style={{color:'white'}}><strong>Bookmaker</strong></TableCell>
            <TableCell align="left" style={{color:'white'}}><strong>Nom</strong></TableCell>
            <TableCell align="left" style={{color:'white'}}><strong>Code</strong></TableCell>            
            <TableCell align="center" style={{color:'white'}}><strong>Lien</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookmakers.map((bookmaker) => (
            <TableRow key={bookmaker.id}>
              <TableCell align="left"><img src={bookmaker.imgSrc}
               width={100} height={80} /></TableCell>
              <TableCell align="left" style={{fontWeight:'bold'}}>{bookmaker.name}</TableCell>
              <TableCell align="left" style={{fontWeight:'bold'}}>{bookmaker.code}</TableCell>              
              <TableCell align="center">
                <Button
                  variant="contained"
                  //color="primary"
                  style={{fontWeight:'bold',
                    backgroundColor:'#435666',
                      fontFamily:'', color:'white'}}
                  onClick={() => window.open(`${bookmaker.lien}`, '_blank')}
                >
                  Ajouter joueur
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    :
    <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Table>
        <TableHead>
          <TableRow style={{
            backgroundColor:'#435666'
          }}>            
            <TableCell align="center" style={{color:'white'}} colSpan={2}><strong>Bookmaker</strong></TableCell>
            </TableRow>
        </TableHead>        
          {bookmakers.map((bookmaker) => (
            <TableBody>
            <TableRow key={bookmaker.id}>
              <TableCell align="center" colSpan={2}><img src={bookmaker.imgSrc}
               width={80} height={70} /></TableCell>              
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{fontWeight:'bold'}}>Nom</TableCell>              
                <TableCell align="center" style={{fontWeight:'bold'}}>Code</TableCell>              
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{fontWeight:'bold'}}>{bookmaker.name}</TableCell>              
                <TableCell align="center" style={{fontWeight:'bold'}}>{bookmaker.code}</TableCell>              
            </TableRow>            
            <TableRow>
            <TableCell align="center" colSpan={2}>
                <Button
                  variant="contained"
                  //color="primary"
                  style={{fontWeight:'bold',
                    backgroundColor:'#435666',
                      fontFamily:'', color:'white'}}
                  onClick={() => window.open(`${bookmaker.lien}`, '_blank')}
                >
                  Ajouter joueur
                </Button>
              </TableCell>
              </TableRow>
            </TableBody>
          ))}        
      </Table>
    </TableContainer>
    }
    </div>
  );
};

export default BookmakersTable;
