import React, { useState,useEffect } from 'react';
import { Box, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

export default function FooterCoIns() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
   

  return (
    <Box
      sx={{
        backgroundColor: '#435666',
        color: 'white',
        p: { xs: 1, sm: 1 }, // Padding adaptatif pour mobile et desktop
        mt: windowWidth>500?30:60,
       
      }}
    >
      
      {/* Copyright */}
      <Box textAlign="center" sx={{ paddingTop:2 }}>
        <Typography variant="body2" fontWeight={'bold'}>
          &copy; {new Date().getFullYear()} BET-Partners. Tous droits réservés.
        </Typography>
      </Box>
    </Box>
  );
}
