import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import {Box, Dialog,DialogActions,DialogContent,
  DialogContentText,DialogTitle,TextField,Autocomplete, Button,MenuItem,
   Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './dasboard.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import caisse1 from '../../images/lottie/caisse1.json'
import caisse2 from '../../images/lottie/caisse2.json'
import caisse3 from '../../images/lottie/caisse3.json'
import teamL from '../../images/lottie/teamL.json'
import userCompte from '../../images/lottie/UserCompte.json'
import folder1 from '../../images/lottie/folder1.json'
import folder2 from '../../images/lottie/folder2.json'
import folder3 from '../../images/lottie/folder3.json'
import partner from '../../images/lottie/partner2.json'
import NavDash from '../navDash';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';


var BACKEND_URL = 'https://backend.bet-partners.com/api/';
    var userParrain =''
    var userEmail =''
    //var totalJoueur =0
    

export default function ReglageSection() {
    //const{ id_user } = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [user,setUser] =useState([]);
    const { id_user,type_user } = useParams();
    var [listMnt,setlistMnt] =useState([])
    var [listCommission,setlistCommission] =useState([])
    var [totalJoueur,setTotalJoueur] =useState([])

    var [montant,setMntMin] =useState(0)
    var [statut_mnt_min,setStatutMin] =useState(0)

    var [montantCom,setMntCom] =useState(0)
    var [statut_activer,setStatutActiver] =useState(0)

    var [totalRegle,setTotalRegle] =useState(0)
    var [totalEnAttente,setTotalEnAttente] =useState(0)
    var [commisionMontant,setCommisionMontant] =useState(0)
    var [totalJoueurNonTraite,setTotalJoueurNonTraite] =useState(0)
    var [totalJoueurTraite,setTotalJoueurTraite] =useState(0)
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
        try{
          /*await axios.get(`${BACKEND_URL}utilisateurs/show/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setUser(data);
              userParrain=data.code_parrainage
              userEmail=data.email
              console.log(data.email)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });*/

          await axios.get(`${BACKEND_URL}montant_mins`)
          .then(({data}) => {
              console.log(data);
              setlistMnt(data);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

          await axios.get(`${BACKEND_URL}commissions`)
          .then(({data}) => {
              console.log(data);
              setlistCommission(data);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

          

          
          await axios.get(`${BACKEND_URL}commissions/active`)
          .then(({data}) => {
              console.log(data);
              setCommisionMontant(data.montant);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

          await axios.get(`${BACKEND_URL}operations/totalCommission/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setTotalEnAttente(data.total_attente);
              setTotalRegle(data.total_regler);
              console.log(data)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });
          
        } catch (error) {
          console.log(error)
        }
      }


      const creationMntMin = async () => {
        try{
          await axios.post(`${BACKEND_URL}montant_mins/create`, {montant,statut_mnt_min})
          .then(({data}) => {
              console.log(data);    
              request()   
              handleCloseDialog();       
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
              console.log(error.response.data.error);
          });
          
        } catch (error) {
          console.log(error)
        }
      }

      const creationComm = async () => {
        try{
          await axios.post(`${BACKEND_URL}commissions/create`, {montantCom,statut_activer})
          .then(({data}) => {
              console.log(data);    
              request()   
              handleCloseDialogCommission();       
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
              console.log(error.response.data.error);
          });
          
        } catch (error) {
          console.log(error)
        }
      }


      const [openCommission, setOpenDialogCommission] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();
    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };
  

    const handleClickOpenDialogCommission = () => {
      setOpenDialogCommission(true);
    };
  
    const handleCloseDialogCommission = () => {
      setBackendErrorMessage('')
      setOpenDialogCommission(false);
    };
  

    useEffect(() => {
        request();
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div>
          <NavDash id_user={id_user} type_user={type_user} />  
        <div class="row" style={{marginTop:15,marginLeft:windowWidth>500?10:0}}>
                  <div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
              <div class="rounded-container" style={{minHeight:windowWidth>500?'400px':'300px', 
                  width:  windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px',
                  backgroundColor: 'white',
                     marginLeft: windowWidth<400?'0px': '5px'}}>
                                    <div class="flex-row" style={{alignItems:'stretch'}}>                                       
                                        <div>
                                            <p style={{ fontSize: 20,
                                              fontWeight: 'bold',
                                              fontFamily:'monospace',
                                              marginBottom: -3, color: 'black'}}>Mnt Min Paiement</p>                                            
                                        </div>
                                        <div style={{ width: '35px', height: '35px', }}>
                                                  <a onClick={() => {}/*handleLinkClick(`approvisionements/${compte_id}`)*/}> 
                                                  <Lottie 
                                                  animationData={caisse1}
                                                  loop={true}                                        
                                                  />
                                                  </a>
                                          </div>
                                    </div>   
                                    <div style={{}}>
                                    {listMnt.map((row, index) => (
                                    
                                    <div key={index} onDoubleClick={() => { 
                                      
                                      //navigate(`/salle/${id_section}/eleve/${row.id_salle}`) 
                                      }}
                                      class="flex-row" 
                                      role='button'
                                      style={{alignItems:'stretch' ,marginBottom:7, borderBottom: '2px solid #ccc'}}
                                      >
                                    
                                                <div>
                                                  <p style={{ fontSize: 18,fontWeight: '',fontFamily:'serif', marginBottom: -3, color: 'black' }}>{row.montant || 'Terminal'}</p>
                                                  <p style={{ fontSize: 13, fontWeight: '',fontFamily:'cursive',
                                                     marginBottom: -3, color: 'green' }}>
                                                      {format(new Date(row.created_at), 'dd-MM-yyyy')} </p>                                                
                                                  </div>
                                                <div style={{ width: '35px', height: '35px' }}>
                                                  <p style={{color:row.statut_mnt_min==true?'green':'red', fontWeight:'bold',fontFamily:'serif'}}>{row.statut_mnt_min==true?'Actif':'Inactif'}</p>
                                                </div>
                                            
                                    </div>
                                    ))}
                                      </div>   
                                      <Button variant="contained" style={{margin: 0}}
                                         onClick={() => {handleClickOpenDialog()}} >
                                        Créer                      
                                      </Button>                                                         
              </div>
              
              
            </div>
            <div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
            <div class="rounded-container" style={{minHeight:windowWidth>500?'400px':'300px', 
                  width:  windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px',
                  backgroundColor: 'white',
                     marginLeft: windowWidth<400?'0px': '5px'}}>
                                    <div class="flex-row" style={{alignItems:'stretch'}}>                                       
                                        <div>
                                            <p style={{ fontSize: 20,
                                              fontWeight: 'bold',
                                              fontFamily:'monospace',
                                              marginBottom: -3, color: 'black'}}>Gestion Commission</p>                                            
                                        </div>
                                        <div style={{ width: '35px', height: '35px', }}>
                                                  <a onClick={() => {}/*handleLinkClick(`approvisionements/${compte_id}`)*/}> 
                                                  <Lottie 
                                                  animationData={caisse2}
                                                  loop={true}                                        
                                                  />
                                                  </a>
                                          </div>
                                    </div>   
                                    <div style={{}}>
                                    {listCommission.map((row, index) => (
                                    
                                    <div key={index} onClick={() => { 
                                      
                                      //navigate(`/salle/${id_section}/eleve/${row.id_salle}`) 
                                      }}
                                      class="flex-row" 
                                      role='button'
                                      style={{alignItems:'stretch' ,marginBottom:7, borderBottom: '2px solid #ccc'}}
                                      >
                                    
                                                <div>
                                                  <p style={{ fontSize: 18,fontWeight: '',fontFamily:'serif', marginBottom: -3, color: 'black' }}>{row.montant || 'Terminal'}</p>
                                                  <p style={{ fontSize: 13, fontWeight: '',fontFamily:'cursive',
                                                     marginBottom: -3, color: 'green' }}>
                                                      {format(new Date(row.created_at), 'dd-MM-yyyy')} </p>                                                
                                                  </div>
                                                <div style={{ width: '35px', height: '35px' }}>
                                                  <p style={{color:row.statut_activer==true?'green':'red', fontWeight:'bold',fontFamily:'serif'}}>{row.statut_activer==true?'Actif':'Inactif'}</p>
                                                </div>
                                            
                                    </div>
                                    ))}
                                      </div>   
                                      <Button variant="contained" style={{alignContent:'end'}}
                                         onClick={() => {handleClickOpenDialogCommission()}} >
                                        Créer                      
                                      </Button>                                                         
              </div>
              </div>
            {/*<div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
            <div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>Nbres De Clients</p>
                                            <p style={{ fontSize: 40,fontWeight: 'bold',
                                            color:'green',
                                              marginBottom: -3,}}>{totalJoueur.length}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: '35px', color:'white' }}>                                                  
                                      <Lottie 
                                        animationData={teamL}
                                        loop={true}                                        
                                        />
                                          </div>
                                          
                                        </div>
                                        <div>
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>Joueurs parrainé par vous</p> 
                                        </div>
                                                                
              </div>
              </div>*/}
            {/*<div class="col-sm-3" style={{}}>                              
            <div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>Code Parrainage</p>
                                            <p style={{ fontSize: 35,fontWeight: 'bold',marginBottom: -3,}}>{''}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: '35px', color:'white' }}>                                                  
                                      <Lottie 
                                        animationData={userCompte}
                                        loop={true}                                        
                                        />
                                          </div>
                                          
                                        </div>
                                        <div>
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>{''}</p> 
                                        </div>
                                                                
              </div>  
            </div>*/}  
            </div>
            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Creation montnant min"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <TextField type='number' 
                                label='Montant'
                                value={montant}
                                name='numero'
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                  setMntMin(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label="Statut"
                                value={statut_mnt_min}
                                style={{minWidth:250}}
                                select
                                onChange={(e)=> {
                                  setStatutMin(e.target.value)
                                }}
                            >
                                <MenuItem key={'Actif'} value={true}>
                                {"Actif"}
                              </MenuItem>
                              <MenuItem key={'Inactif'} value={false}>
                                {'Inactif'}
                              </MenuItem>
                            </TextField>
                              
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {
                              creationMntMin()
                            }} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>    
            

                <Dialog
                  //fullScreen={fullScreen}
                  open={openCommission}
                  onClose={handleCloseDialogCommission}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Creation Commission"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <TextField type='number' 
                                label='Montant'
                                value={montantCom}
                                name='numero'
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                  setMntCom(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label="Statut"
                                value={statut_activer}
                                style={{minWidth:250}}
                                select
                                onChange={(e)=> {
                                  setStatutActiver(e.target.value)
                                }}
                            >
                                <MenuItem key={'Actif'} value={true}>
                                {"Actif"}
                              </MenuItem>
                              <MenuItem key={'Inactif'} value={false}>
                                {'Inactif'}
                              </MenuItem>
                            </TextField>
                              
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {
                              creationComm()
                            }} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogCommission} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>    
            
            </div>
    )
}
