import React from 'react';
import { Typography, Box } from '@mui/material';

const GuideSection1 = () => {
  return (
    <Box sx={{ 
      padding: 2, 
      margin: 'auto', 
      maxWidth: 800, 
      textAlign: 'center', 
      '@media (max-width: 600px)': {
        padding: 1, 
        maxWidth: '100%',
      }
    }}>
      <Typography variant="h4" sx={{ 
        fontWeight: 'bold',
        marginBottom: 2,
        '@media (max-width: 600px)': {
          fontSize: '1.5rem',
        }
      }}>
        Une activité très pratiquée
      </Typography>
      <Typography variant="body1" sx={{
        textAlign:'left',
        fontSize: '1.2rem',
        '@media (max-width: 600px)': {
          fontSize: '1rem',
        }
      }}>
       Beaucoup de raisons font des paris sportifs l’un des types de paris
        les plus populaires. Entre autres sa nature relativement simple
         et son côté distrayant. Cependant, certains aspects de cette forme 
         de jeu peuvent être un peu plus complexes.
        C’est vrai que pour commencer par placer des paris,
         on n’a pas besoin d’avoir beaucoup de connaissances,
          mais il faudrait connaitre beaucoup plus que les 
          bases si vous espérez en tirer quelque chose. 
          Cela est particulièrement vrai si vous aspirez
           à gagner régulièrement de l’argent.
            Même si vous pariez juste pour le plaisir, 
            en savoir plus sur la façon de le faire ne vous fera pas de mal.

        Peu importe si vous n’avez jamais placé de paris 
        sportifs dans votre vie, ou si vous avez beaucoup
         d’expérience de paris à votre actif.
          Peu importe que votre objectif soit
           de vous amuser ou de gagner de l’argent. 
           Vous trouverez ce guide utile.

Pour les débutants, il y a des informations sur
 le fonctionnement des paris sportifs et ce qui
  est impliqué. Il existe également de nombreux 
  conseils de base très faciles à comprendre.
   De plus, vous y trouverez des sujets avancés pour 
   les parieurs plus expérimentés et beaucoup de conseils
    stratégiques.

 
 

Nous vous aidons même à trouver les meilleurs endroits 
pour parier en ligne. Le principal objectif de ce
 guide de paris sportifs est de vous apprendre tout 
 ce qu’il y a à savoir sur le sujet. Il est le fruit 
 du travail acharne d’une équipe de véritables experts,
  qui ont des années d’expérience en paris sportifs. 
  Les informations et les conseils que nous avons à offrir ici n’ont pas de prix.

Maintenant, nous ne promettons des millions de gains du jour au lendemain. 
C’est plus un article informatif qu’une garantie de gains colossaux. 
Combinez les informations et conseils que nous vous donnons ici avec 
 bonne attitude et beaucoup de volonté, vous pourriez commencer 
 par gagner régulièrement.
</Typography>
    </Box>
  );
}

export default GuideSection1;
