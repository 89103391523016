import React, { useState, useEffect } from 'react';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import services from '../../images/lottie/services.json'




export default function FirstSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5">
            <div className="row align-items-center">
                {/* Partie gauche : contenu */}
                <div className="col-md-6">
                    <div className="card border-0" style={{ padding: '10px' }}>                    
                        <Stack direction={'row'} alignItems={'center'} spacing={0}>
                        <p className="card-title" style={{fontWeight:'bold', color:'#435666', width:200, fontSize:windowWidth>500? 25:20}} >
                            Nos services
                        </p>
                        <div style={{width:140,
                             height:7,
                             borderRadius:5,
                             backgroundColor:'#435666'}}></div>                        
                        </Stack>
                        <p className="card-text" style={{fontWeight:'', fontSize: windowWidth>500?  20:15}}>
                        Nous proposons de nombreux services dans l’univers des paris sportifs
                         à savoir la création de caisse de dépôt et de 
                        retrait 1xbet, la création des comptes joueurs sur 
                        (1xbet, BetWinner, 1win, Melbet, Linebet), 
                        la création de code promo 
                        personnalisé et l’appui conseils.
                          </p>
                          
                    </div>
                </div>
                
                {/* Partie droite : image */}
                <div className="col-md-6">
                <Lottie animationData={services} loop style={{
                            height:windowWidth>500? 500:350,
                            width:windowWidth>500? 500:350
                        }}/>
                </div>
            </div>
        </div>
    );
}
