import React, { useState, useEffect } from 'react';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import mobileWallet from '../../images/lottie/mobileWallet.json'





export default function SecondSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5" style={{
            backgroundColor:'#435666',
            borderRadius:'30px',
            padding:20,
            margin: ''
        }}>
            <div className="row align-items-center">
                {/* Partie gauche : contenu */}
                <div className="col-md-6">
                    <div className="card border-0" style={{ padding: '10px', backgroundColor:'#435666' }}>                    
                        <p className="card-title" style={{fontWeight:'bold',color:'white', fontSize:windowWidth>500? 27:20}} >
                        Devenez partenaire de BET-Partners et augmentez vos revenus. 
                        </p>
                        {/*<p className="card-text" style={{fontWeight:'',color:'white', fontSize: windowWidth>500?  20:15}}>
                        Profitez de votre code promo exclusif, partagez-le avec vos amis...
                         et regardez vos revenus grimper ! 🤑 Plus vous partagez, plus vous gagnez !
                         🚀 Qui a dit que faire de l'argent ne pouvait pas être amusant ? 😉  </p>*/}
                          <Stack direction={'row'} alignItems={'center'} spacing={2}>
                          <Lottie animationData={start} loop style={{
                            height:100,
                            width:100
                        }}/>
                          <Button variant="contained"
                          href='/inscription'
                           style={{width:250, height:40,
                             backgroundColor:'white',
                             color:'#435666',
                             fontWeight:'bold'}}>
                            Devenez partenaire
                          </Button>

                          </Stack>
                          
                    </div>
                </div>
                
                {/* Partie droite : image */}
                <div className="col-md-6">
                <Lottie animationData={mobileWallet} loop style={{
                            height:windowWidth>500? 500:350,
                            width:windowWidth>500? 500:350
                        }}/>
                </div>
            </div>
        </div>
    );
}
