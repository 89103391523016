import logo from './logo.svg';
import './App.css';
import * as React from "react";
import {createBrowserRouter, RouterProvider } from "react-router-dom";
import Accueil from './pages/accueil';
import APropos from './pages/apropos';
import Service from './pages/service';
import Partenaire from './pages/partenaires';
import Inscription from './pages/inscription';
import Connexion from './pages/connexion';
import Dashboard from './pages/dashboard';
import { List } from '@mui/material';
import ListOperationsSection from './pages/composants/DashboardSection/ListOperationsSection';
import PaiementSection from './pages/composants/DashboardSection/paiementSection';
import ReglageSection from './pages/composants/DashboardSection/reglageSection';
import InfoSection from './pages/composants/DashboardSection/infoSection';
import PartenaireAttente from './pages/composants/DashboardSection/PartenaireAttente';
import CCMSection from './pages/composants/AccueilSection/ccmSection';
import CCM from './pages/ccm';
import Bookmaker from './pages/bookmaker';
import Guide from './pages/guide';
import Contact from './pages/contact';
import AddJoueurSection from './pages/composants/DashboardSection/addJoueurSection';
import MonCompteSection from './pages/composants/DashboardSection/monCompteSection';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Accueil />,
  },
  {
    path: "/a-propos",
    element: <APropos />,
  },
  {
    path: "/service",
    element: <Service />,
  },
  {
    path: "/partenaire",
    element: <Partenaire />,
  },
  {
    path: "/ccm",
    element: <CCM />,
  },
  {
    path: "/bookmaker",
    element: <Bookmaker />,
  },
  {
    path: "/guide",
    element: <Guide />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/inscription",
    element: <Inscription />,
  },
  {
    path: "/connexion",
    element: <Connexion />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/dashboard/:id_user/:type_user",
    element: <Dashboard />,
  },
  {
    path: "/dashboard/:id_user/:type_user/operations",
    element: <ListOperationsSection />,
  },
  {
    path: "/dashboard/:id_user/:type_user/paiements",
    element: <PaiementSection />,
  },
  {
    path: "/dashboard/:id_user/:type_user/reglage",
    element: <ReglageSection />,
  },
  {
    path: "/dashboard/:id_user/:type_user/info",
    element: <InfoSection />,
  },
  {
    path: "/dashboard/:id_user/:type_user/addJoueur",
    element: <AddJoueurSection />,
  },
  {
    path: "/dashboard/:id_user/:type_user/monCompte",
    element: <MonCompteSection />,
  },

  {
    path: "/dashboard/:id_user/:type_user/partenaire",
    element: <PartenaireAttente />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
