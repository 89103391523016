import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './dasboard.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import caisse1 from '../../images/lottie/caisse1.json'
import caisse2 from '../../images/lottie/caisse2.json'
import caisse3 from '../../images/lottie/caisse3.json'
import teamL from '../../images/lottie/teamL.json'
import userCompte from '../../images/lottie/UserCompte.json'
import folder1 from '../../images/lottie/folder1.json'
import folder2 from '../../images/lottie/folder2.json'
import folder3 from '../../images/lottie/folder3.json'


var BACKEND_URL = 'https://backend.bet-partners.com/api/';
    var userParrain =''
    var userEmail =''
    //var totalJoueur =0
    

export default function SoldeSection({id_user, type_user, code_parrainage, email}) {
    //const{ id_user } = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [user,setUser] =useState([]);
    var [totalJoueur,setTotalJoueur] =useState(0)
    var [totalJoueurNonTraite,setTotalJoueurNonTraite] =useState(0)
    var [totalJoueurTraite,setTotalJoueurTraite] =useState(0)
    var [totalRegle,setTotalRegle] =useState(0)
    var [totalEnAttente,setTotalEnAttente] =useState(0)
    var [commisionMontant,setCommisionMontant] =useState(0)
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
        try{
          /*await axios.get(`${BACKEND_URL}utilisateurs/show/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setUser(data);
              userParrain=data.code_parrainage
              userEmail=data.email
              console.log(data.email)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });*/

          await axios.get(`${BACKEND_URL}joueurs/totalJoueur/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setTotalJoueur(data.total_joueurs);
              setTotalJoueurNonTraite(data.totalJoueursNonTraite)
              setTotalJoueurTraite(data.totalJoueursTraite)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

          await axios.get(`${BACKEND_URL}commissions/active`)
          .then(({data}) => {
              console.log(data);
              setCommisionMontant(data.montant);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

          await axios.get(`${BACKEND_URL}operations/totalCommission/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setTotalEnAttente(data.total_attente);
              setTotalRegle(data.total_regler);
              console.log(data)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });
          
        } catch (error) {
          console.log(error)
        }
      }

    useEffect(() => {
        request();
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div>
        <div class="row" style={{marginTop:15}}>
                  <div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
                    <div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>{type_user=='Partenaire'?'Solde Non Réglé':'Dossiers Traités'}</p>
                                            <p style={{ fontSize: 40,fontWeight: 'bold',marginBottom: -3,}}>{type_user=='Partenaire'?totalEnAttente:totalJoueurTraite}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: '35px', color:'white' }}>                                                  
                                      <Lottie 
                                        animationData={type_user=='Partenaire'?caisse2:folder3}
                                        loop={true}                                        
                                        />
                                          </div>
                                          
                                        </div>
                                        <div>
                                          {type_user=='Partenaire'?
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>Commission Active: <span style={{fontSize: 13,
                                          color:'red',
                                          fontWeight:'bold',
                                           fontFamily:'monospace'}}>
                                          {commisionMontant}
                                        </span> FCFA</p>
                                        :
                                        <p style={{fontSize: 13,
                                          color:'GrayText',
                                          fontWeight:'bold',
                                           fontFamily:'monospace'}}>Joueurs Eligibles</p>
                                         }
                                        </div>
                                                                
              </div>
              
            </div>
            <div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
            <div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>{type_user=='Partenaire'?'Solde Réglé':'Dossiers Non Traités'}</p>
                                            <p style={{ fontSize: 40,fontWeight: 'bold',
                                            color:type_user=='Partenaire'?totalJoueurNonTraite>totalJoueurTraite?'red':'':'',
                                              marginBottom: -3,}}>{type_user=='Partenaire'?totalRegle:totalJoueurNonTraite}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: '35px', color:'white' }}>                                                  
                                      <Lottie 
                                        animationData={type_user=='Partenaire'?caisse1:folder3}
                                        loop={true}                                        
                                        />
                                          </div>
                                          
                                        </div>
                                        <div>
                                        {type_user=='Partenaire'?
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>Commission Active: <span style={{fontSize: 13,
                                          color:'red',
                                          fontWeight:'bold',
                                           fontFamily:'monospace'}}>
                                          {commisionMontant}
                                        </span> FCFA</p> 
                                        :
                                        <p style={{fontSize: 13,
                                          color:'GrayText',
                                          fontWeight:'bold',
                                           fontFamily:'monospace'}}>Joueurs en attentes de vérification</p>
                                         }
                                        </div>
                                                                
              </div>
              </div>

            <div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
            <div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>{type_user=='Partenaire'?'Joueurs':'Commission Cumul'}</p>
                                            <p style={{ fontSize: 40,fontWeight: 'bold',
                                            color:'green',
                                              marginBottom: -3,}}>{totalJoueur}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: '35px', color:'white' }}>                                                  
                                      <Lottie 
                                        animationData={teamL}
                                        loop={true}                                        
                                        />
                                          </div>
                                          
                                        </div>
                                        <div>
                                          {type_user=='Partenaire'?
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>Joueurs Parrainés Par Vous</p> 
                                        :
                                        <p style={{fontSize: 13,
                                          color:'GrayText',
                                          fontWeight:'bold',
                                           fontFamily:'monospace'}}>Total des commissions</p> 
                                           }
                                        </div>
                                                                
              </div>
              </div>
            <div class="col-sm-3" style={{}}>                              
            {/*<div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>Votre Code Promo</p>
                                            <p style={{ fontSize: 35,fontWeight: 'bold',marginBottom: -3,}}>{code_parrainage!=''?code_parrainage:''}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: '35px', color:'white' }}>                                                  
                                      <Lottie 
                                        animationData={userCompte}
                                        loop={true}                                        
                                        />
                                          </div>
                                          
                                        </div>
                                        <div>
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>Faites la promo pour gagner plus</p> 
                                        </div>
                                                                
              </div>*/}  
            </div>  
            </div>
            </div>
    )
}
