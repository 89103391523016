import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import {Box, Dialog,DialogActions,DialogContent,
  DialogContentText,DialogTitle,TextField,Autocomplete, Button,MenuItem,
   Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './dasboard.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import caisse1 from '../../images/lottie/caisse1.json'
import caisse2 from '../../images/lottie/caisse2.json'
import caisse3 from '../../images/lottie/caisse3.json'
import teamL from '../../images/lottie/teamL.json'
import userCompte from '../../images/lottie/UserCompte.json'
import folder1 from '../../images/lottie/folder1.json'
import folder2 from '../../images/lottie/folder2.json'
import folder3 from '../../images/lottie/folder3.json'
import partner from '../../images/lottie/partner2.json'
import NavDash from '../navDash';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import ListClientsSection from './ListClientsSection';
import BookmakersTable from '../AccueilSection/bookmakerTable';


var BACKEND_URL = 'https://backend.bet-partners.com/api/';
    var userParrain =''
    var userEmail =''
    //var totalJoueur =0
    

export default function AddJoueurSection() {
    //const{ id_user } = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [user,setUser] =useState([]);
    const { id_user,type_user } = useParams();
    var [listMnt,setlistMnt] =useState([])
    var [listCommission,setlistCommission] =useState([])
    var [totalJoueur,setTotalJoueur] =useState([])

    var [montant,setMntMin] =useState(0)
    var [statut_mnt_min,setStatutMin] =useState(0)

    var [montantCom,setMntCom] =useState(0)
    var [statut_activer,setStatutActiver] =useState(0)

    var [totalRegle,setTotalRegle] =useState(0)
    var [totalEnAttente,setTotalEnAttente] =useState(0)
    var [commisionMontant,setCommisionMontant] =useState(0)
    var [totalJoueurNonTraite,setTotalJoueurNonTraite] =useState(0)
    var [totalJoueurTraite,setTotalJoueurTraite] =useState(0)
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
        try{
          /*await axios.get(`${BACKEND_URL}utilisateurs/show/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setUser(data);
              userParrain=data.code_parrainage
              userEmail=data.email
              console.log(data.email)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });*/

          await axios.get(`${BACKEND_URL}montant_mins`)
          .then(({data}) => {
              console.log(data);
              setlistMnt(data);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

          await axios.get(`${BACKEND_URL}commissions`)
          .then(({data}) => {
              console.log(data);
              setlistCommission(data);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

          

          
          await axios.get(`${BACKEND_URL}commissions/active`)
          .then(({data}) => {
              console.log(data);
              setCommisionMontant(data.montant);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

         
        } catch (error) {
          console.log(error)
        }
      }


      

      

      const [openCommission, setOpenDialogCommission] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();
    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };
  

    const handleClickOpenDialogCommission = () => {
      setOpenDialogCommission(true);
    };
  
    const handleCloseDialogCommission = () => {
      setBackendErrorMessage('')
      setOpenDialogCommission(false);
    };
  

    useEffect(() => {
        request();
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div>
          <NavDash id_user={id_user} type_user={type_user} />  
          <BookmakersTable />
        </div>
    )
}
