import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from './composants/nav';
//import './dasboard.css';
import { useParams,useNavigate } from "react-router-dom";
import {Dialog,DialogActions, Box,DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete} from '@mui/material';

import Footer from './composants/footer';
import FirstSection from './composants/DPartenaireSection/firstSection';
import SecondSection from './composants/DPartenaireSection/secondSection';
import ThirdSection from './composants/DPartenaireSection/thirdSection';
import NavDash from './composants/navDash';
import SoldeSection from './composants/DashboardSection/SoldeSection';
import ListClientsSection from './composants/DashboardSection/ListClientsSection';
import OpérateurFirstSection from './composants/DashboardSection/adminFirstSection';
import AdminFirstSection from './composants/DashboardSection/adminFirstSection';
import ListUsersAdminSection from './composants/DashboardSection/ListUsersAdminSection';

var BACKEND_URL = 'https://backend.bet-partners.com/api/';

export default function Dashboard() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { id_user,type_user } = useParams();
  const [user,setUser] =useState();
  //var [type_user,setType_User] = useState('')
  var [code_parrainage, SetCode_parrainage] = useState('');
  var [email,setEmail]=useState('')
  const [backendErrorMessage, setBackendErrorMessage] = useState('');

  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

const request = async () => {
  try{
    await axios.get(`${BACKEND_URL}utilisateurs/show/${id_user}`)
    .then(({data}) => {
        console.log(data);
        setUser(data);
        //setType_User(data.type_user);
        SetCode_parrainage(data.code_perso);
        setEmail(data.email);
        console.log(type_user)
    }).catch ((error) =>
    {
        setBackendErrorMessage(error.response.data.error);
    });
    
  } catch (error) {
    console.log(error)
  }
}

useEffect(() => {
  request();
  console.log(type_user);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
};
window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
};
}, []);
    return (
        <div>
            <NavDash id_user={id_user} type_user={type_user}  />  
            {type_user!='Admin'?
            <>
            <SoldeSection id_user={id_user} code_parrainage={code_parrainage} email={email} type_user={type_user} />   
            <ListClientsSection id_user={id_user}  type_user={type_user}  />
            </>
            :
            <>   
            <AdminFirstSection id_user={id_user} code_parrainage={code_parrainage} email={email} type_user={type_user} />
            <ListUsersAdminSection id_user={id_user}  type_user={type_user}  />                        
            </>
            }
        </div>
    );
  
}