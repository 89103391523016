import React from 'react';
import { Stack, Typography, IconButton, Link } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';

const contacts = [
  {
    type: 'Email',
    icon: <MailOutlineIcon color="primary" />,
    value: 'support@bet-partners.com',
    action: () => window.location.href = 'mailto:support@bet-partners.com',
  },
  {
    type: 'WhatsApp',
    icon: <WhatsAppIcon style={{ color: '#25D366' }} />,
    value: '+226 77 17 02 02',
    action: () => {
        const message = encodeURIComponent('Bonjour, je voudrais avoir plus d\'informations sur BET-Partners.');
        window.open(`https://wa.me/22677170202?text=${message}`, '_blank');
      },
  },
  {
    type: 'Téléphone',
    icon: <PhoneIcon color="secondary" />,
    value: '+226 77 17 02 02',
    action: () => window.location.href = 'tel:+22677170202',
  },
];

const ContactsPage = () => {
  return (
    <Stack spacing={3} sx={{ padding: 4, alignItems: 'flex-start' }}>
      <Typography variant="h4"style={{fontFamily:'monospace'}} gutterBottom>
        Contacts de l'Entreprise
      </Typography>
      {contacts.map((contact, index) => (
        <Stack
          key={index}
          direction="row"
          spacing={2}
          alignItems="center"
          onClick={contact.action}
          sx={{
            cursor: 'pointer',
            '&:hover': { backgroundColor: '#f0f0f0', borderRadius: 2, padding: 1 },
          }}
        >
          <IconButton>{contact.icon}</IconButton>
          <Typography variant="body1">{contact.value}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default ContactsPage;
