import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from './composants/nav';
//import './dasboard.css';
import { useParams,useNavigate } from "react-router-dom";
import {Dialog,DialogActions, Box,DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete} from '@mui/material';

import Footer from './composants/footer';
import ContactsPage from './composants/ContactSection/contactSection';




export default function Contact() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
};
window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
};
}, []);
    return (
        <div>
            <Nav />   
            <ContactsPage />
            <Footer />
        </div>
    );
  
}