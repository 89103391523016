import React from 'react';
import { Box, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import bet from '../images/bet.png'
import betLyrics from '../images/bet-Lyrics.png'

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#435666',
        color: 'white',
        p: { xs: 4, sm: 6 }, // Padding adaptatif pour mobile et desktop
        mt: 4,
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Section: À propos de nous */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom fontWeight={'bold'}>
            À propos de nous
          </Typography>
          <img src={bet} height={80} width={80} />
          <Typography variant="body2">
          Nous sommes une entreprise spécialisée dans
          <span style={{fontWeight:'bold'}}>l’accompagnement des partenaires de paris sportifs</span> pour
          <span style={{fontWeight:'bold'}}>optimiser leurs performances</span> et
          <span style={{fontWeight:'bold'}}>maximiser leur présence en ligne.</span> 
          🎯 Que vous soyez un <span style={{fontWeight:'bold'}}>bookmaker</span>
           ou un <span style={{fontWeight:'bold'}}>promoteur affilié</span>, 
          nous mettons à votre disposition des outils innovants et un suivi personnalisé 
          pour booster vos résultats et accroître votre visibilité. 🚀
          </Typography>
        </Grid>

        {/* Section: Liens utiles */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom fontWeight={'bold'}>
            Liens utiles
          </Typography>
          <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
            Accueil
          </Link>
          <Link href="/service" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
            Services
          </Link>
          <Link href="/partenaire" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
            Devenir partenaire
          </Link>
          <Link href="/a-propos" color="inherit" underline="hover" sx={{ display: 'block' }}>
            À propos
          </Link>
          <Link href="/inscription" color="inherit" underline="hover" sx={{ display: 'block' }}>
            Inscription
          </Link>
        </Grid>

        {/* Section: Contact */}
        <Grid item xs={12} sm={4}>
        <Typography variant="h6" gutterBottom fontWeight={'bold'}>
            Contact
          </Typography>
          <Box>
            {/* Icônes de réseaux sociaux */}
            <IconButton onClick={(e)=>{
               const message = encodeURIComponent('Bonjour, je voudrais avoir plus d\'informations sur BET-Partners.');
               window.open(`https://wa.me/22677170202?text=${message}`, '_blank');
            }} color="inherit" aria-label="WhatsApp">
                <WhatsAppIcon />
            </IconButton>
            <IconButton href="mailto:support@bet-partners.com" color="inherit" aria-label="Email">
              <EmailIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Copyright */}
      <Box textAlign="center" sx={{ mt: 4, pt: 2, borderTop: '1px solid #444' }}>
        <Typography variant="body2" fontWeight={'bold'}>
          &copy; {new Date().getFullYear()} BET-Partners. Tous droits réservés.
        </Typography>
      </Box>
    </Box>
  );
}
