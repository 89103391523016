import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import {Box, Dialog,DialogActions,DialogContent,
  DialogContentText,DialogTitle,TextField,Autocomplete, Button,MenuItem,
   Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './dasboard.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import accountUser from '../../images/lottie/accountUser.json'
import NavDash from '../navDash';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import ListClientsSection from './ListClientsSection';
import BookmakersTable from '../AccueilSection/bookmakerTable';


var BACKEND_URL = 'https://backend.bet-partners.com/api/';
    var userParrain =''
    var userEmail =''
    //var totalJoueur =0
    

export default function MonCompteSection() {
    //const{ id_user } = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //const [user,setUser] =useState([]);
    const { id_user,type_user } = useParams();
    var [utilisateur,setUser] =useState([])
    var [listCommission,setlistCommission] =useState([])
    var [totalJoueur,setTotalJoueur] =useState([])

    var [montant,setMntMin] =useState(0)
    var [statut_mnt_min,setStatutMin] =useState(0)

    var [montantCom,setMntCom] =useState(0)
    var [statut_activer,setStatutActiver] =useState(0)

    var [totalRegle,setTotalRegle] =useState(0)
    var [totalEnAttente,setTotalEnAttente] =useState(0)
    var [commisionMontant,setCommisionMontant] =useState(0)
    var [totalJoueurNonTraite,setTotalJoueurNonTraite] =useState(0)
    var [totalJoueurTraite,setTotalJoueurTraite] =useState(0)
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
        try{
          /*await axios.get(`${BACKEND_URL}utilisateurs/show/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setUser(data);
              userParrain=data.code_parrainage
              userEmail=data.email
              console.log(data.email)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });*/

          
          await axios.get(`${BACKEND_URL}utilisateurs/show/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setUser(data);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });

         
        } catch (error) {
          console.log(error)
        }
      }


      

      

      const [openCommission, setOpenDialogCommission] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();
    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };
  

    const handleClickOpenDialogCommission = () => {
      setOpenDialogCommission(true);
    };
  
    const handleCloseDialogCommission = () => {
      setBackendErrorMessage('')
      setOpenDialogCommission(false);
    };
  

    useEffect(() => {
        request();
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div>
          <NavDash id_user={id_user} type_user={type_user} />  
          <div style={styles.container}>
      <div style={styles.card}>
        {/* Image de profil */}
        <div style={styles.imageContainer}>
        <Lottie animationData={accountUser} loop 
        style={styles.profileImage}/>
            
        </div>

        {/* Informations de l'utilisateur */}
        <div style={styles.infoContainer}>
          <h2 style={styles.userName}>{utilisateur.nom + ' ' + utilisateur.prenom}</h2>
          <p style={styles.userInfo}><strong>Email :</strong> {utilisateur.email}</p>
          <p style={styles.userInfo}><strong>Numéro de téléphone :</strong> {utilisateur.numero}</p>
          <p style={styles.userInfo}><strong>Moyen de paiement :</strong> {utilisateur.libelle_paie}</p>
          <p style={styles.userInfo}><strong>Référence de paiement :</strong> {utilisateur.ref_paie}</p>          
          <p style={styles.userInfo}><strong>Pays :</strong> {utilisateur.libelle_pays}</p>
          <p style={styles.userInfo}><strong>Genre :</strong> {utilisateur.genre}</p>
          </div>
      </div>
    </div>
        </div>
    )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px',
    backgroundColor: '#f4f4f9',
    minHeight: '100vh',
  },
  card: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: '500px',
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '30px 20px',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  profileImage: {
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    objectFit: 'cover',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  infoContainer: {
    marginTop: '20px',
    color: '#333',
    //alignItems:'left'
  },
  userName: {
    fontSize: '1.6rem',
    fontWeight: '600',
    margin: '10px 0',
    color: '#444',
  },
  userInfo: {
    fontSize: '1rem',
    color: '#666',
    margin: '5px 0',
    lineHeight: '1.5',
  },
};
