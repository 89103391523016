import React from 'react';
import { Typography, Box } from '@mui/material';

const GuideSection = () => {
  return (
    <Box sx={{ 
      padding: 2, 
      margin: 'auto', 
      maxWidth: 800, 
      textAlign: 'center', 
      '@media (max-width: 600px)': {
        padding: 1, 
        maxWidth: '100%',
      }
    }}>
      <Typography variant="h4" sx={{ 
        fontWeight: 'bold',
        marginBottom: 2,
        '@media (max-width: 600px)': {
          fontSize: '1.5rem',
        }
      }}>
        Le parchemin du parieur sportif : guide ultime pour parier sur les sports
      </Typography>
      <Typography variant="body1" sx={{
        textAlign:'left',
        fontSize: '1.2rem',
        '@media (max-width: 600px)': {
          fontSize: '1rem',
        }
      }}>
        Les paris sportifs ne datent pas d’aujourd’hui ni d’hier.
         Ils sont même considérés comme l’une des formes de jeu 
         les plus anciennes qui existent si ce n’est la plus ancienne. 
         Les gens pariaient déjà sur le sport depuis des siècles. 
         Bien que beaucoup de choses aient changes avec le temps,
          le concept de base est resté le même. 
          Ce concept consiste à mettre une mise généralement sous forme 
          d’argent sur un évènement qu’on juge le plus probable de se réaliser. 
          Vous gagnez si votre prédiction est correcte et vous perdez si ce n’est pas le cas.
        </Typography>
    </Box>
  );
}

export default GuideSection;
