import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import {BDivider, CircularProgress, Tooltip, Container} from '@mui/material';
import {CircularProgress, Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import './dasboard.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import caisse1 from '../../images/lottie/caisse1.json'
import caisse2 from '../../images/lottie/caisse2.json'
import caisse3 from '../../images/lottie/caisse3.json'
import teamL from '../../images/lottie/teamL.json'
import userCompte from '../../images/lottie/UserCompte.json'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


var BACKEND_URL = 'https://backend.bet-partners.com/api/';
var countData=1;


export default function ListUsersAdminSection({id_user, type_user}) {
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //const [user,setUser] =useState([]);
    //var [type_userR,setType_UserR] = useState('')
    const [users, setUsers] = useState([]);
    const [listBookmaker, setListBookmaker] = useState([]);

    const datePaiement = new Date().toISOString().slice(0, 19).replace('T', ' ');

    const [backendErrorMessage, setBackendErrorMessage] = useState('');


    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = users.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(users.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };


    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();

    const [formData, setFormData] = useState({
      partenaire_id: id_user,
      nom: "",
      prenom: "",
      numero: "",
      bookmaker: "",
      statut_joueur: "Traitement",
      identifiantJoueur: "",
      date_enregistrement: "",
    });
  
    // Gérer les changements de chaque champ du formulaire
    const handleChangeAjout = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setBackendErrorMessage('');
    };
  

    const ajouterJoueur = async (formData) => {
      try {
        // Effectuer l'appel API avec axios
        const response = await axios.post(`${BACKEND_URL}joueurs/create`, formData);
        
        // Si la requête est réussie, afficher les données du joueur ajouté
        console.log("Joueur ajouté avec succès :", response.data);
        request();
        handleCloseDialog();
        return response.data;
      } catch (error) {
        // En cas d'erreur, afficher le message correspondant
          setBackendErrorMessage(error.response.data.error);       
          console.log(error.response.data.messages);
      }
    };

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setBackendErrorMessage('')
        setOpenDialog(false);
      };
      

      const validerPaiement = async (id_part) => {
        try{
          
          await axios.post(`${BACKEND_URL}paiements/create/${id_part}`, { date_paiement: datePaiement })
          .then(({data}) => {
              console.log(data);    
              request()        
              setBackendErrorMessage('')
              handleCloseDialog()  
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
              console.log(error.response.data.error);
          });
          
        } catch (error) {
          console.log(error)
        }
      }

      const request = async () => {
        try{

    await axios.get(`${BACKEND_URL}utilisateurs`)
          .then(({data}) => {
              console.log(data);
              setUsers(data);
              console.log(data)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });        
        
        } catch (error) {
          console.log(error)
        }
      }

    useEffect(() => {
        request();
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
      <div>
        <Stack direction={'column'}
              alignItems='center'
              sx={{marginTop: 3,
                marginLeft: 2, 
                marginRight: 2, 
                borderRadius: 5, 
                minWidth:windowWidth-150,
                boxShadow: '0px 0px 5px #2B2A4C',
                backgroundColor:'white'}}>     
              <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
              Liste des utilisateurs
            </Stack>                         
            <Stack>
            {/*<div style={{marginBottom: 20}}>
                    <TextField type="text" 
                          value={searchQuery} 
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    </div>*/}
                    {windowWidth>700?
              <TableContainer component={Paper}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={9}>Utilisateurs</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>                                                                                                                                                     
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >E-mail</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>                                                                                                                                 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pays</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Cummul Non Payé</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Preférences</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ref Paie</TableCell>   
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                 
                              </TableRow>
                                  {data.map((row, index) => (
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>                                                                                                                                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.email}</TableCell>                                           
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.numero}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.libelle_pays} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,color:row.type_user=='Partenaire'?'green':'#405D72'}}>{ row.type_user} </TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, color:'red'}}>{row.total_en_attente}</TableCell>   
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.libelle_paie}</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.ref_paie}</TableCell>                                      
                                      <TableCell align="left" style={{fontWeight: 600}}>   
                                            <Stack direction={'column'}
                                                spacing={{sm: 1}}
                                                alignItems={'center'}                                                   
                                            >
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}} >
                                                <Tooltip title='Faire le paiement'>
                                                  <IconButton style={{ color: row.statut_joueur != 'Valider'? '#EB8317':'red' }} onClick={() => { row.total_en_attente>0? validerPaiement(row.id):console.log('Nothing') }} >
                                                    {row.statut_joueur != 'Valider'? <AttachMoneyIcon />: <CancelIcon />}
                                                  </IconButton>
                                                </Tooltip>
                                                {/*<Tooltip title='Voir'>
                                                  <IconButton style={{ color: '#0D9276' }} onClick={() => { navigate(`/afficher-sortie/${row.id}`) }} >
                                                    <ArrowForwardIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {/*Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip>  */}                                                                                           
                                                </Stack>
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}}>                                                                                                       
                                                </Stack>                                                    
                                            </Stack>                                             
                                                                                       
                                      </TableCell>
                                              </TableRow>
                                                ))}
                                          </TableBody>
                      </Table>
              </TableContainer>
              :
              <TableContainer component={Paper}>
                      <Table sx={{ minWidth:windowWidth-100, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Utilisateurs</TableCell>
                              </TableRow>
                          </TableHead>
                          {data.map((row, index) => (
                          <TableBody>
                              <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >E-mail</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" colSpan={2} style={{fontWeight: 600}}>{row.email}</TableCell>     
                                      
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell> 
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pays</TableCell>                                                                                                                                 
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600}}>{row.numero}</TableCell>                                        
                                      <TableCell align="center" style={{fontWeight: 600}}>{row.libelle_pays}</TableCell>
                                      
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Preférences</TableCell>
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.type_user} </TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.libelle_paie} </TableCell>                                      
                                      </TableRow>                                                                            
                                      
                                      <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Références Paie</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" colSpan={2} style={{fontWeight: 600,}}>{row.ref_paie}</TableCell>     
                                      
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Cumul Non Payé</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" colSpan={2} style={{fontWeight: 600, color:'red'}}>{row.total_en_attente}</TableCell>     
                                      
                                    </TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                
                              <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600}}>   
                                            <Stack direction={'column'}
                                                spacing={{sm: 1}}
                                                alignItems={'center'}                                                   
                                            >
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}} >
                                                <Tooltip title='Valider'>
                                                  <IconButton style={{  color: row.statut_joueur != 'Valider'? '#EB8317':'red' }} onClick={() => { 
                                                    row.total_en_attente>0? validerPaiement(row.id):console.log('Nothing') }} >
                                                    { row.statut_joueur!= 'Valider'?<AttachMoneyIcon />:<CancelIcon />}
                                                  </IconButton>
                                                </Tooltip>
                                                {/*<Tooltip title='Voir'>
                                                  <IconButton style={{ color: '#0D9276' }} onClick={() => { /*navigate(`/afficher-sortie/${row.id}`) }} >
                                                    <ArrowForwardIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {/*Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip>*/}                                                                                             
                                                </Stack>
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}}>                                                                                                       
                                                </Stack>                                                    
                                            </Stack>                                             
                                                                                       
                                      </TableCell>
                                              </TableRow>
                                                
                                          </TableBody>
                                          ))}
                      </Table>
              </TableContainer>
                                              }
              <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                  <nav > 
                      <ul className='pagination'>
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={prePage}>Préc</a>
                          </li>
                          {
                            getPageNumbersToShow().map((n,i) =>
                            (
                              <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                <a href='#' className='page-link'
                                onClick={() => changeCPage(n)}>{n}</a>
                              </li>
                            )
                            )
                          }
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={nextPage}>Suiv</a>
                          </li>
                      </ul>
                  </nav>
                  <div>
                    <h5>Total {users.length}</h5> 
                  </div>
          </div>
            </Stack>            
            </Stack>
                </div>
    )
     
    function nextPage(){
      if(firstIndex+10 < users.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
}