import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import iconIt from './iconIt.png';
import 'bootstrap/dist/js/bootstrap'
import './composants.css';
import {useNavigate } from "react-router-dom";
import bet from '../images/bet.png'
import betLyrics from '../images/bet-Lyrics.png'


export default function Nav() {

    const [activeLink, setActiveLink] = useState('');

    const [backendErrorMessage, setBackendErrorMessage]= useState([])

    
    const navigate = useNavigate();

    const handleLinkClick = (link) => {
        setActiveLink(link);
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };



    useEffect(() => {
      try {
       
        
      } catch (error) {
        console.log(error)
      }

    });
    return (
        <div class='divFirst' style={{backgroundColor:'black'}}>
            <nav class="navbar navbar-expand-sm navbar-dark navbar-custom"
            style={{backgroundColor:'#435666', color:'white'}}>
            <div class="container-fluid">
            <img
              src={bet}
              onClick={() => {navigate(`/`)}}
              width={50}
              height={50} />              
              <a class="navbar-brand text-nav-colors"
               href="javascript:void(0)"
               style={{fontFamily:'monospace', color:'white'}}
               onClick={() => handleLinkClick(``)}>BET-Partners</a>
              <button class="navbar-toggler" style={{backgroundColor: '#273A4B'}} type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                <span class="navbar-toggler-icon" style={{backgroundColor: ''}}></span>
              </button>
              <div class="collapse navbar-collapse" id="mynavbar">
                <ul class="navbar-nav me-auto"> 
                  <li className={`nav-item `}>
                    <a class="nav-link text-nav-colors " style={{color:'white'}} role='button' onClick={() => {navigate(`/ccm`)}}>CCM</a>
                  </li>                                 
                  <li className={`nav-item`}>
                    <a class="nav-link text-nav-colors " style={{color:'white'}} role='button' onClick={() => {navigate(`/service`)}}>Services</a>
                  </li>
                  <li className={`nav-item `}>
                    <a class="nav-link text-nav-colors " style={{color:'white'}} role='button' onClick={() => {navigate(`/partenaire`)}}>Devenir partenaire</a>
                  </li>
                  <li className={`nav-item `}>
                    <a class="nav-link text-nav-colors " style={{color:'white'}} role='button' onClick={() => {navigate(`/contact`)}}>Contact</a>
                  </li>
                  <li className={`nav-item`}>
                      <div class="dropdown">
                      <a class="nav-link font-bold dropdown-toggle" style={{color:'white'}} role="button" data-bs-toggle="dropdown" aria-expanded="false">Plus</a>
                      <ul class="dropdown-menu">                        
                        <li>
                          <a class="dropdown-item" role='button' onClick={() => {navigate(`/bookmaker`)}}>Bookmaker</a>
                        </li>
                        <li>
                          <a class="dropdown-item" role='button' onClick={() => {navigate(`/guide`)}}>Guide des paris</a>
                        </li>
                        <li>
                          <a class="dropdown-item" role='button' onClick={() => {navigate(`/a-propos`)}}>A propos</a>
                        </li>
                      </ul>
                      </div>
                </li>
                
                </ul>                
                <Button variant="outlined" style={{margin: 2, color:'white'}}  onClick={() => {navigate(`/connexion`)}} >
                    Connexion                      
                </Button>
                <Button variant="contained" style={{margin: 2, backgroundColor:'#273A4B'}}  onClick={() => {navigate(`/inscription`)}} >
                    Inscription                      
                </Button>
              </div>
            </div>
              </nav>
              <script></script>
    </div>
    );
}